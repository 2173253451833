/* Define CSS Variables */
:root {
  --primary-color: #d22828;
  /* --primary-color: #4aa53f; */
  --old-primary-color: #26bb26;
  --secondary-color: #d22828;
  --background-color: #141414;
  --text-color: #ffffff;
  --footer-background: #000000;
  --banner-color: #e0e0e0;
  --font-family: 'Komika Axis', sans-serif;
}


/* Global Styles */
body {
  margin: 0;
  font-family: var(--font-family);
  overflow-x: hidden;
  background-color: var(--secondary-color);
  color: var(--text-color);
}

.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden; /*Prevent horizontal scrolling*/
}


/* Section Token Info Styles */
.sectionTokenInfo {
  background-color: var(--background-color);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sectionTokenInfo-title {
  margin: 20px 0px;
  width: 100%;
}

.sectionTokenInfo-main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px 20px;
}

.sectionTokenInfo-image-left {
  flex: 1;
  padding-right: 20px; /* Space between left and right images */
}

.sectionTokenInfo-image-left img {
  width: 60%;
  height: auto;
}

.sectionTokenInfo-images-right {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.sectionTokenInfo-image-top,
.sectionTokenInfo-image-bottom {
  width: 60%;
  height: auto;
  margin-bottom: 20px; /* Space between top and bottom images */
}

.sectionTokenInfo-image-bottom {
  transition: transform 0.3s;
}

.sectionTokenInfo-image-bottom:hover {
  transform: scale(1.1);
}

.sectionTokenInfo-image-bottom {
  margin-bottom: 0; /* Remove bottom margin from the last image */
}

/* Mobile Styles */
@media (max-width: 768px) {
  .sectionTokenInfo-main {
    flex-direction: column;
    align-items: center;
    margin: 20px 10px;
  }
  
  .sectionTokenInfo-title-image{
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }

  .sectionTokenInfo-image-left {
    padding-right: 0;
    margin-bottom: 20px; /* Space between images */
    width: 90%;
  }

  .sectionTokenInfo-image-left-img {
    width: 100%;
  }

  .sectionTokenInfo-images-right {
    flex-direction: column;
    align-items: center;
  }

  .sectionTokenInfo-image-top,
  .sectionTokenInfo-image-bottom {
    width: 80%;
    margin-bottom: 15px; /* Space between top and bottom images */
  }

  .sectionTokenInfo-image-bottom {
    margin-bottom: 0; /* Remove bottom margin from the last image */
  }
}

@media (max-width: 480px) {
  .sectionTokenInfo-image-left img {
    width: 100%;
  }

  .sectionTokenInfo-image-top,
  .sectionTokenInfo-image-bottom {
    width: 90%;
  }
}


/* src/styles/Section3_5.css */

/* Section 3.5 Styles */
.section3_5 {
  background-color: var(--background-color);
  padding: 40px 20px; /* Adjusted padding for more space at the bottom */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section3_5-content {
  text-align: center;
  width: 100%;
}

.section3_5-title {
  width: 80%;
  max-width: 600px;
  margin-bottom: 30px; /* Increased margin bottom for more space */
  margin-top: 30px; /* Added margin top for more space */
}

.section3_5-subtitle {
  width: 70%;
  max-width: 500px;
  margin-bottom: 50px; /* Increased margin bottom for more space */
}

.section3_5-steps-container {
  display: flex;
  justify-content: center;
  max-width: 100%;
  margin-bottom: 30px; /* Added margin bottom for more space between steps and bottom of section */
  margin-top: 30px;
}

.section3_5-steps {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 100px; /* Increased gap for more space between steps */
  justify-items: center;
  
}

.section3_5-step-wrapper {
  display: flex;
  justify-content: center;
  width: 90%;
}

.section3_5-step {
  width: 100%;
  max-width: 450px;
  transition: transform 0.6s;
}

.section3_5-step:hover {
  transform: scale(1.2);
}

@media (max-width: 768px) {
  .section3_5 {
    padding: 20px;
  }

  .section3_5-title {
    width: 100%;
    margin-bottom: 20px; /* Increased margin bottom for mobile */
    margin-top: 20px; /* Added margin top for mobile */
  }

  .section3_5-subtitle {
    width: 90%;
    margin-bottom: 30px; /* Increased margin bottom for mobile */
  }

  .section3_5-steps {
    grid-template-columns: 1fr;
    gap: 20px;
  }

  .section3_5-step {
    max-width: 350px;
   
  }
}



/* NavBar Styles */
.navbar {
  display: flex;
  align-items: center;
  background-color: var(--primary-color);
  padding: 10px 20px;
  border-radius: 20px;
  margin: 20px 40px; /* Add spacing on the sides of the navbar */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

/* Logo styles for desktop */
.navbar-logo-container {
  position: absolute;
  left: 20px; /* Adjust for spacing from the left */
}

.navbar-logo {
  height: 50px; /* Adjust size as needed */
}

/* Navigation links container */
.navbar-links-container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.navbar-links li {
  display: flex;
  align-items: center;
}

.navbar-links a {
  text-decoration: none;
  color: var(--text-color);
  font-weight: bold;
  padding: 10px 15px;
  transition: transform 0.3s, background-color 0.3s;
  border-radius: 15px;
}

.navbar-links a:hover {
  transform: scale(1.1);
  background-color: var(--secondary-color);
}

.navbar-menu {
  display: none;
  cursor: pointer;
}


/* Close button styles */
.navbar-close {
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 1.5em;
  color: var(--text-color);
  cursor: pointer;
  transition: color 0.3s;
}
.navbar-close {
  display: hidden;
}

.navbar-close:hover {
  color: var(--background-color);
}

/* Social icons container for desktop */
.navbar-social-container {
  display: flex;
  gap: 15px;
  position: absolute;
  right: 20px; /* Adjust for spacing from the right */
}

.navbar-social-icon {
  height: auto;
  width: 40px;
  transition: transform 0.3s;
}
/* Mobile Styles */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: center;
    margin: 20px; /* Add spacing around the mobile navbar */
  }

  .navbar-logo-container {
    margin-bottom: 10px;
    position: static;
  }

  .navbar-links {
    display: none;
  }

  .navbar-menu {
    display: block;
    cursor: pointer;
  }

  .navbar-links-mobile {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: var(--primary-color);
    position: absolute;
    top: 75px;
    width: 80%;
    padding: 20px;
    border-radius: 0 0 20px 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 0 20px; /* Add spacing on the sides of the mobile menu */
    z-index: +1;
  }

  .navbar-link-mobile {
    color: var(--text-color);
    text-decoration: none;
    font-size: 1.2em;
    transition: color 0.3s;
  }

  .navbar-link-mobile:hover {
    color: var(--secondary-color);
  }
  .navbar-social-container {
    display: none;
    gap: 10px;
    margin-top: 20px;
  }
  .navbar-social-icon {
    height: 25px;
    width: 25px;
  }
}


.sub-header {
  background-color: var(--primary-color);
  height: 20px;
}

.sections-container {
  flex: 1;
  
}

/* Header Styles */
.App-header {
  background-color: var(--primary-color);
  padding: 20px;
}

.logo-container {
  display: flex;
  justify-content: center;
  
}

.App-logo {
  padding-top: 50px;
  height: 120px;
}

/* Section 1 Styles */
.section1 {
  background-color: var(--primary-color);
  padding: 20px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.section1-main {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.section1-main-content-left,
.section1-main-description-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
}

.section1-main-content-left {
  margin-left: 30%;
}



.section1-main-description-right-lottie {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 500px;
  padding-bottom: 100px;
  padding-top: 100px;
  z-index: 1;
}

.section1-main-description-right{
  margin-right: 8%;
}
.section1-main-content-left-lottie {
  width: 80%;
  height: auto;
  bottom: 0px;
  position: absolute;
  padding-left: 200px;
}

.character {
  height: 500px;
  position: relative;
  /* margin-right: 20px; */
  transition: transform 0.8s;

}

.description {
  max-height: 100px;

}

.button {
  height: 100px;
  margin-top: 25px;
  cursor: pointer;
  transition: transform 0.3s;
}

.button:hover {
  transform: scale(1.1);
}


.section1-footer {
  overflow: visible;
  position: relative;
  width: 100%;
  max-height: 100px; 
}

.banner-wrapper {
  display: flex;
  width: 200%;
}

.banner-image {
  width: 50%;
  height: 100%;
  animation: scroll 10s linear infinite;
}

/* Keyframes for Banner */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

/* Section 2 Styles */
.section2 {
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 20px;
}

.section2-main {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section2-title-container,
.section2-content-container {
  max-width: 100%;
  margin-bottom: 20px;
}

.section2-content {
  max-width: 100%;
}

/* Section 3 Styles */
.section3 {
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 20px;
}

.section3-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.section3-title,
.section3-sub-title-content {
  max-width: 90%;
  margin-bottom: 20px;
}

.section3-title {
  font-size: 2em;
}

.section3-paragraph-container {
  max-width: 50%;
}

.section3-paragraph {
  font-size: 1.5em;
  margin-top: 20px;
  line-height: 1.4;
  max-width: 100%;
}



/* Section 4 Styles */
.section4 {
  background-color: var(--primary-color);
  color: var(--text-color);
  padding: 20px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: column;
}

.section4-title {
  font-size: 2em;
  margin-bottom: 20px;
  padding-top: 30px;
  
}

.social-media-icons {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.social-icon {
  height: 60px;
  margin: 20px 10px;
  transition: transform 0.3s;
}

.social-icon:hover {
  transform: scale(1.3);
}

.section4-main {
  width: 100%;
}

.section4-content-container {
  max-width: 100%;
  display: flex;
  flex-direction: row-reverse;
}

.section4-content-left {
  height: auto;
  width: 100%;
  
}

.section4-content-left-image {
  max-width: 100%;
  height: 100%;
}

.section4-content-right {
  display: flex;
  flex-direction: column;
  margin-right: 20%;
  padding-top: 5%;
}

.section4-content-left-lottie{
  position: relative;
  bottom:0px;
  min-width: 55%;
  


}
.section4-content-right-lottie {
  display: flex;
  flex-direction: column;
  margin-right: 15%;
  margin-top: 4%;
}

.section4-button {
  height: 150px;
  margin: 20px 0;
  cursor: pointer;
  transition: transform 0.3s;
}

.section4-button:hover {
  transform: scale(1.1);
}

.section4-underbutton{
  max-width: 500px;
  
 }

/* Footer Styles */
.footer {
  background-color: var(--footer-background);
  padding: 20px 0;
  color: var(--text-color);
  width: 100%;
  text-align: center;
}

.footer-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
}

.placeholder-text {
  margin: 20px auto;
  font-size: 2em;
  font-weight: bold;
  max-width: auto;
  line-height: 1.4;
}

.footer-image {
  height: auto;
 width: 80%;

}

.footer-image-2 {
  padding-top: 20px;
  padding-bottom: 20px;
  height: auto;
  width: 40%;
  transition: transform 0.3s;
}


.footer-image-2:hover {
  transform: scale(1.1);
}

.footer-bow-div{
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.footer-bow-image{
  height: auto;
  max-width: 300px;
  margin-left: 50px;
  margin-right: 20px;
  margin-bottom: 20px;
  transition: transform 0.3s;
}

.footer-bow-image:hover{
  transform: scale(1.1);
}

/* src/styles/Layers.css */
.layers-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}


.info-icon{
  cursor: pointer;
}

.image-window {
  background-color: #444444;
  position: relative;
  margin: 20px auto;
  width: 100%;
  max-width: 500px;
  height: auto;
  aspect-ratio: 1/1;
  border: 5px solid #080808;
}

.layerButton {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #26bb26;
  color: white;
  border: none;
  border-radius: 5px;
  margin: 10px auto;
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s;
  margin-left: 25px;
  margin-right: 25px;
}

.layerButton:hover {
  background-color: #45a049;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.control-buttons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0;
}

.control-buttons div {
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.control-buttons button {
  padding: 5px 10px;
  font-size: 18px;
  background-color: #130101;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 25px;
  margin-right: 25px;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.control-buttons button:hover {
  background-color: #0069d9;
}


@media (max-width: 600px) {
  .image-window {
    max-width: 300px;
    max-height: 300px;
  }

  .control-buttons {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .control-buttons div {
    justify-content: space-around;
  }
}


/* Add this to your existing CSS */
/* wallet generator page */
/* Loading overlay styling */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.63);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.loading-overlay p {
  margin-top: 20px; /* Add spacing between loading symbol and text */
}

.loading-spinner {
  border: 4px solid rgba(12, 12, 12, 0.678);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}
.loading-text{
  color: white;
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
  max-width: 35%;

}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* Add styling for the buttons in the loading overlay */
.loading-overlay button {
  margin-top: 20px;
}

.go-back-button {
  position: absolute; /* This positions the button relative to the .wallet-info container */
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/*chain logos*/
.image-row {
  display: block  ;
  align-items: center;
  margin-bottom: 20px; /* Space below the images */
  border: 2px solid rgb(63, 58, 58); /* Add a border if needed */
  border-radius: 50% 5% 50% 5%; /* Makes the image circular */
  padding: 25px 40px;
  background-color: rgb(63, 58, 58);

}

.crypto-image {
  max-width: 70px; /* Adjust the size as needed */
  height: auto;
  border-radius: 50%; /* Makes the image circular */
  border: 2px solid #ccc; /* Add a border if needed */
  background-color: white;
  margin: 0 10px; /* Adjust the spacing as needed */
}



.wallet-generator {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.wallet-info {
  background-color: #565a555e;
  position: relative;
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  max-width: 100%;

}


.seed-phrase-container,
.backup-confirmation {
  margin-top: 20px;
}

.blurred-seed {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.blurred-seed button {
  margin-top: 10px;
}

input {
  margin: 5px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: #0056b3;
}

.copy-button {
  margin-top: 10px;
  padding: 5px;
  border: none;
  border-radius: 4px;
  background-color: #28a745;
  color: white;
  cursor: pointer;
}

.copy-button:hover {
  background-color: #218838;
}

/* Add this to your existing CSS */
/* Styling for the copy button */
.copy-button {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding: 5px;
  border: none;
  border-radius: 4px;
  background-color: #28a745;
  color: white;
  cursor: pointer;
}

.copy-button:hover {
  background-color: #218838;
}

.copy-button svg {
  margin-right: 5px;
}
@media (max-width: 600px) {
  .loading-text{
    max-width: 75%;
  
  }

}


/*end of wallet generator page */

/* Global Mobile Adjustments */
@media (max-width: 768px) {
  .section1-main {
    flex-direction: column-reverse;
    align-items: center;
    width: auto;
  }

  .App-logo {
    padding-top: 50px;
    min-height: 80px;
  }
  

  .character {
    max-height: 350px;
    margin-left: 60%;
  }


  .section1-main-description-right{
  
  margin-right: 0%;

  }

  .section1-main-description-right-lottie {
    align-items: center;
    padding-left: 0px;
    padding-bottom: 300px;
    padding-top: 20px;
  
   
  }
 
  .section1-main-content-left{
    margin-left: 0px;
  }

  .section1-main-content-left-lottie {
    width: 180%;
    height: auto;
    bottom: 0px;
    position: absolute;
    padding-left: 600px;
  }
  .section1 {
    padding: 0;
    margin: 0;
  }

  .section1-footer {
    height: auto;
    width: 100%;
  }

  .banner-image {
    width: 2000px;
    height: auto;
 
  }

  .description {
    width: 75%;
  }

  .footer-content {
    padding: 0 10px;
  }

  .section2,
  .section3,
  .section4 {
    padding: 10px;
  }

  .section4 {
    padding: 0;
  }

  .section2-title {
    width: 100%;
  }
  .section2-title-container,
  .section2-content-container,
  .section3-title,
  .section3-sub-title,
  .section4-title {

    max-width: 90%;
    margin-top: 30px;
  }

  .section3-paragraph-container {
    width: 90%;
  }

  .section3-paragraph {
    font-size: 1.2em;
    padding: 0 30px;
  }

  .section4-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .section4-content-right {
    margin-right: 0;
    width: 80%;
  }
  

  .section4-content-left {
    display: flex;
    bottom: 0px;
    height: auto;
    width: auto;
    margin-right: 0;
  }

  .section4-content-left-lottie{
    width:100%;
    bottom:0px;
    margin-left: 0%;
  
  }
  .section4-content-right-lottie {
    display: flex;
    flex-direction: column;
    margin-right: 0%;
    margin-top: 0%;
    
  }
  
  .section4-content-left-image {
    width: 350px;
    bottom: 0px;
    height: auto;
  }

  .section4-button {
    height: 130px;
    margin: 25px 0;
    margin-left: 20px;
  }

    .section4-underbutton{
     max-width: 250px;
     padding: 20px, 0px;
    }
  .social-icon {
    height: 50px;
    margin: 0 10px;
  }
}
