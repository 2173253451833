    /* src/styles/walletGenSolana.css */

    /* Container for Solana wallet generator */
    .solana-wallet-generator {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px;
    }

    .keypair-info {
        position: relative;
        background-color: #554b4b8c;
        border: 1px solid #ddd;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 4px 8px rgba(0,0,0,0.1);
        width: 100%;
        max-width: 650px;
        margin-top: 20px;
    }

    .keypair-info div {
        margin-bottom: 10px;
    }

    .keypair-info strong {
        display: block;
        margin-bottom: 5px;
    }

    .keypair-info span {
        display: block;
        margin-bottom: 5px;
        word-break: break-all;
    }

    .copy-button {
        padding: 5px 10px;
        border: none;
        border-radius: 4px;
        background-color: #28a745;
        color: white;
        cursor: pointer;
    }

    .copy-button:hover {
        background-color: #218838;
    }

    /* Additional styling for inputs and buttons */
    input {
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 4px;
        margin-bottom: 10px;
    }

    button {
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        background-color: #007bff;
        color: white;
        cursor: pointer;
        margin-top: 10px;
    }

    button:hover {
        background-color: #0056b3;
    }

    /* Loading overlay styles */
    .loading-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.5);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .loading-spinner {
        border: 8px solid rgba(0,0,0,0.1);
        border-left-color: #fff;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation: spin 1s linear infinite;
    }

    .loading-text {
        color: white;
        margin-top: 20px;
        text-align: center;
    }

    @keyframes spin {
        to { transform: rotate(360deg); }
    }

    /* Loading overlay styling */
    .loading-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.63);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 999;
    }
    
    .loading-overlay p {
        margin-top: 20px; /* Add spacing between loading symbol and text */
    }
    
    .loading-spinner {
        border: 4px solid rgba(12, 12, 12, 0.678);
        border-top: 4px solid #fff;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 1s linear infinite;
    }
    .loading-text{
        color: white;
        font-size: 20px;
        font-weight: bold;
        margin-top: 10px;
        max-width: 35%;
    
    }
    
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
    
    .go-back-button {
        position: absolute; /* This positions the button relative to the .wallet-info container */
        top: 10px;
        right: 10px;
        padding: 5px 10px;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;
    }
    /*chain logos*/
    .image-row-sol {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin: 20px 0; /* Space below the images */
    }
    
    .crypto-image-sol {
        max-width: 70%; /* Adjust the size as needed */
        height: auto;
        border-radius: 30%; /* Makes the image circular */
        border: 2px solid #ccc; /* Add a border if needed */
        background-color: white;
        margin: 0 10px; /* Adjust the spacing as needed */
    }
    
